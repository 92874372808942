<div class="bg-grey d-flex flex-wrap justify-content-center align-items-center row-gap-2 column-gap-4 my-4 p-3 rounded-4">
    <div class="text-3xl font-bold leading-max tracking-1">{{contest.amount}} €</div>
    <div class="text-xl font-bold leading-2 tracking-2 text-center text-sm-start">
        de carte cadeaux à <br>
        gagner en vous inscrivant
    </div>
</div>
@if (!hideStatusMsg) {
    <div class="text-center text-base font-light leading-1">
        @if (expired) {<span data-cy="expired">Notre jeu concours est terminé</span>}
        @else {
            Notre jeu concours n'est pas encore ouvert <br>
            Rendez-vous le
            <span class="text-xl font-medium leading-2" data-cy="startAt">
                {{ contest.startAt | date:'dd MMMM yyyy':'undefined':'fr-FR' }}
            </span> pour participer
        }
    </div>
}
