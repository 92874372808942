import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, switchMap, tap} from 'rxjs';
import {AuthenticationService, Contest, MsServicesContestService} from '@isifid/core';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UiService {
    public contest$: BehaviorSubject<Contest | null> = new BehaviorSubject<Contest | null>(null);

    private readonly msServicesContestService = inject(MsServicesContestService);
    private readonly authenticationService = inject(AuthenticationService);

    public defaultBackgroundImageCss = 'body{background: url("https://cdn.isifid.com/static/cc8537ce-05e1-48b0-b20d-3b4733c9bb2c.png") no-repeat;}';

    public get configuration$(): Observable<Record<string, any>> {
        return this.contest$.pipe(
            map((s) => JSON.parse(s?.configuration ?? '{}'))
        );
    }

    public init(): Observable<Contest> {
        return this.msServicesContestService.getContestByDomain().pipe(
            switchMap((s) => this.authenticationService.initWithTokens(s).pipe(map(() => s))),
            tap((s) => this.contest$.next(s))
        );
    }

    public handleMissingImage(event: ErrorEvent) {
        // If 404 display image-not-available.png
        (event.target as HTMLImageElement).src = environment.cdnUrl + '/marketplace/img/image-not-available.png';
    }
}
