import {booleanAttribute, Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {DatePipe} from '@angular/common';
import {Contest} from '@isifid/core';


@Component({
    selector: 'app-status',
    standalone: true,
    imports: [
        MatIconModule,
        DatePipe
    ],
    templateUrl: './status.component.html',
    styleUrl: './status.component.scss'
})
export class StatusComponent {
    @Input() contest: Contest = new Contest();
    @Input() expired: boolean = false;
    @Input({transform: booleanAttribute}) hideStatusMsg: boolean = false;
}
