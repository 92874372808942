import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    Attempt,
    CacheService,
    Contest,
    FormValidators,
    MobileService,
    MsServicesContestService,
    Player
} from '@isifid/core';
import {ActivatedRoute} from '@angular/router';
import {delay, finalize, switchMap} from 'rxjs';

import {SpinnerComponent} from '../spinner/spinner.component';
import {StatusComponent} from '../status/status.component';

@Component({
    selector: 'app-form',
    standalone: true,
    providers: [
        FormValidators,
        MobileService,
        MsServicesContestService
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        SpinnerComponent,
        StatusComponent
    ],
    templateUrl: './form.component.html',
    styleUrl: './form.component.scss'
})
export class FormComponent implements OnInit {
    loading: boolean = false;
    showWarning: boolean = false;
    mobileRegexWithoutCountryCode = /^[67]\d{8}$/;
    formGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required, this.formValidators.isName]),
        lastName: new FormControl('', [Validators.required, this.formValidators.isName]),
        email: new FormControl('', [Validators.required, this.formValidators.isEmail]),
        phone: new FormControl('', [Validators.required, Validators.pattern(this.mobileRegexWithoutCountryCode)]),
        externalId: new FormControl('', [Validators.minLength(5), Validators.maxLength(30)]),
        accept: new FormControl(false, Validators.requiredTrue)
    });

    @Input() contest!: Contest;
    @Output() goToNextStep: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formValidators: FormValidators,
        private readonly msServicesContestService: MsServicesContestService,
        private readonly mobileService: MobileService,
        private readonly cacheService: CacheService
    ) {
    }

    ngOnInit() {
        if (!this.contest.regulationUrl) this.formGroup.patchValue({accept: true});
        if (Object.values(this.route.snapshot.queryParams).length) {
            this.formGroup.patchValue({
                firstName: this.route.snapshot.queryParams['firstName'],
                lastName: this.route.snapshot.queryParams['lastName'],
                email: this.route.snapshot.queryParams['email'],
                phone: this.getMobileValue(),
                externalId: this.route.snapshot.queryParams['externalId']
            });
        }
    }

    onSubmit(): void {
        this.showWarning =
            this.formGroup.controls.firstName.valid &&
            this.formGroup.controls.lastName.valid &&
            this.formGroup.controls.email.valid &&
            this.formGroup.controls.phone.valid;

        if (this.formGroup.valid) {
            this.loading = true;
            this.cacheService.addPermanentContent('first_name', this.formGroup.controls.firstName.value);
            const payload= this.formGroup.value;
            payload.phone = '+33' + payload.phone;
            delete payload.accept;

            const player = new Player();
            Object.assign(player, payload);
            if (this.contest.clientId) player.clientId = this.contest.clientId;
            this.msServicesContestService.createPlayer(player)
                .pipe(
                    switchMap(({id}) => {
                        const attempt: Attempt = {...new Attempt(), playerId: id ?? -1, contestId: this.contest.id ?? -1};

                        return this.msServicesContestService.createAttempt(attempt);
                    }),
                    delay(5000),
                    finalize(() => this.loading = false)
                )
                .subscribe((res) => this.goToNextStep.emit(res.won));
        }
    }

    private getMobileValue(): string {
        const mobile = this.mobileService.formatMobile(this.route.snapshot.queryParams['mobile']);
        return mobile.startsWith('+33') ? mobile.substring(3) : mobile;
    }
}
