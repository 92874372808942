import {Routes} from '@angular/router';
import {CardComponent} from './components/card/card.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';

export const routes: Routes = [
    {
        path: '',
        component: CardComponent
    },
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
