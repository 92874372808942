@if (loading) {
    <app-spinner class="my-3 py-3 fadeIn" />
    <div class="text-base font-medium leading-1">
        Le tirage au sort a commencé, encore quelques secondes...
    </div>
}
@else {
    <app-status [contest]="contest" hideStatusMsg/>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="row">
        <mat-form-field class="col-md-6">
            <mat-label>Prénom</mat-label>
            <input matInput formControlName="firstName" placeholder="Julie" data-cy="firstName" />
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Nom</mat-label>
            <input matInput formControlName="lastName" placeholder="Humeau" data-cy="lastName" />
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <mat-label>Adresse email</mat-label>
            <input matInput formControlName="email" placeholder="julie.humeau@gmail.com" data-cy="email" />
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <mat-label>Numéro de téléphone</mat-label>
            <span matTextPrefix>+33 &nbsp;</span>
            <input matInput formControlName="phone" placeholder="623456789" data-cy="phone" />
        </mat-form-field>

        @if (contest.regulationUrl) {
            <div class="col-md-12 d-flex justify-content-between align-items-center my-1">
                <div class="text-base font-light leading-1">
                    J’ai lu et j’accepte
                    <a class="text-base font-medium leading-1 color-secondary" [href]="contest.regulationUrl" target="_blank">le règlement</a>
                </div>

                <mat-slide-toggle formControlName="accept" data-cy="accept"></mat-slide-toggle>
            </div>
        }

        <div class="col-md-12 tracking-3 color-primary warning-msg">
            @if (showWarning && formGroup.controls.accept.invalid) {
                Vous devez accepter les règles.
            }
        </div>

        <div class="col-md-12 mt-4">
            <button mat-fab extended color="primary" class="w-100 text-lg font-medium leading-1 tracking-2 p-4 rounded-3" data-cy="submit">
                Je tente ma chance
                <mat-icon aria-hidden="false" aria-label="Gift icon" fontIcon="arrow_forward" color="white" iconPositionEnd/>
            </button>
        </div>
    </form>
}
