@if (currentStep === 'loading') {
    <div class="w-100 h-100 d-flex">
        <app-spinner class="m-auto fadeIn" />
    </div>
} @else {
    <div class="d-none d-md-block">
        <app-logo />
    </div>
    @if (currentStep === 'result') {
        <app-result class="w-100 fadeIn" [success]="success" />
    } @else {
        <mat-card class="p-4 w-100">
            <div class="px-1 py-2">
                <div class="d-flex d-md-none justify-content-center">
                    <app-logo />
                </div>
                <div class="d-flex justify-content-center align-items-center gap-2 color-primary text-base font-medium leading-1 tracking-2">
                    <mat-icon aria-hidden="false" aria-label="Gift icon" fontIcon="card_giftcard" color="primary" />
                    Tentez votre chance!
                </div>
                @if (currentStep === 'status') {<app-status class="fadeIn" [contest]="contest" [expired]="expired"/>}
                @else if (currentStep === 'form') {<app-form class="fadeIn" [contest]="contest" (goToNextStep)="showResult($event)" data-cy="form" />}
            </div>
        </mat-card>
    }
}
