import {Component, Input} from '@angular/core';
import {MatCard} from '@angular/material/card';
import {MatFabButton} from '@angular/material/button';
import {CacheService} from '@isifid/core';
import {LogoComponent} from '../logo/logo.component';

@Component({
    selector: 'app-result',
    standalone: true,
    imports: [
        MatCard,
        MatFabButton,
        LogoComponent
    ],
    templateUrl: './result.component.html',
    styleUrl: './result.component.scss'
})
export class ResultComponent {
    firstName: String = '';

    @Input() success: boolean = false;

    constructor(
        private readonly cacheService: CacheService
    ) {
        this.firstName = this.cacheService.getContent('first_name');
    }
}
