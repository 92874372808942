<mat-card class="p-4">
    <div class="px-1 py-2">
        <div class="d-flex d-md-none justify-content-center mb-3">
            <app-logo />
        </div>
        @if (!success) {
            <div class="text-2xl font-bold leading-3 tracking-2" data-cy="failed-result">Malheureusement, ce n’est pas pour cette fois!</div>
            <div class="text-base font-normal leading-1 tracking-3 mt-4">
                Dommage {{firstName}}, cette fois-ci la chance n'était pas de votre côté. Ne vous découragez pas, continuez de participer pour de futures opportunités de gagner!
            </div>
        } @else {
            <div class="text-2xl font-bold leading-3 tracking-2" data-cy="success-result">Bravo {{firstName}}, c’est gagné!</div>
            <div class="bg-grey text-center my-4 p-3 rounded-4">
                <div class="text-3xl font-bold leading-max tracking-1">50 €</div>
                <div class="text-xl font-bold leading-2 tracking-2">
                    de carte cadeaux
                </div>
            </div>
            <div class="text-base font-normal leading-1 tracking-3 mt-4">
                Bien joué {{firstName}}, la chance était de votre côté. Vous allez recevoir vos accès par email et par SMS dans un délai de 15 jours ouvrés.
            </div>
        }
    </div>
</mat-card>

<mat-card class="mt-4 p-4" [hidden]="true">
    <div class="px-1 py-2">
        <div class="text-base font-normal leading-1 tracking-3 mb-3">
            Si vous ne mettez pas de poison, la masse des joueurs sera présente. Un Mauris vient de tirer sur un Elifend. Laoreet en pur grâce au ciblage de la fermentation du diesel du lac. L'élément dans le brun libre maintenant lui-même que le football jusqu'à. Ni le football.
            Pour devenir parrain
        </div>
        <div>
            <button mat-fab extended color="primary" class="w-100 text-lg font-medium leading-1 tracking-2 p-4 rounded-3">
                Devenir parrain
            </button>
        </div>
    </div>
</mat-card>
